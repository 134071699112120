import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import { rhythm, scale } from '../utils/typography'

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(2.5),
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 50,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <div>
              <p>
                Personal blog written by{' '}
                <a href={`https://twitter.com/${social.twitter}`}>
                  <strong>{author}</strong>
                </a>
                <br />I like math and I like code. Oh, and writing too.
                {/* <span style={{textTransform: 'uppercase', fontSize: '0.8rem'}}>Current Location</span> Prague, Czech Republic<br />
              <span style={{textTransform: 'uppercase', fontSize: '0.8rem'}}>Current Activity</span> building useful things in React Native at{' '}
              <a href="https://kiwi.com">Kiwi.com</a>. */}
              </p>
            </div>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Bio
